import Shepherd from 'shepherd.js';
import API_UPD_GUIDE from '../API/user/updGuide';

export function startGuide1() {
    return () => {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
              modalOverlayOpeningRadius: 20,
              modalOverlayOpeningPadding: 20,
              cancelIcon: {
                enabled: true
              },
              when: {
                cancel: function() {
                    API_UPD_GUIDE.request(1)
                },
              },
            },
          });
  
          tour.addSteps([
            {
              title: 'AI 분석',
              attachTo: { element: '#gd_gnb0', on: 'right-start' },
              text: `AI분석 메뉴를 클릭하여 척추 및 수부 엑스레이 분석을 진행합니다.`,
              buttons: [
                {
                  action: tour.next,
                  text: '다음'
                },
              ],
            },
            {
              title: '환자 등록',
              attachTo: { element: '#gd_regBtn', on: 'left-start' },
              text: `신규환자를 등록할 수 있습니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '다음',
                  action: tour.next,
                }
              ],
            },
            {
              title: '환자 선택',
              attachTo: { element: '#gd_p_list', on: 'top' },
              text: `분석 할 환자를 선택합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '다음',
                  action: tour.next,
                }
              ],
            },
            {
              title: '분석 시작',
              attachTo: { element: '#gd_start', on: 'left-start' },
              text: `선택한 환자의 엑스레이 이미지를 등록합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '완료',
                  action() {
                    API_UPD_GUIDE.request(1);
                    return this.complete();
                  },
                }
              ],
            }
          ]);

          tour.start();
    };
}


export function startGuide2() {
    return () => {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
              modalOverlayOpeningRadius: 20,
              modalOverlayOpeningPadding: 20,
              cancelIcon: {
                enabled: true
              },
              when: {
                cancel: function() {
                    API_UPD_GUIDE.request(2)
                },
              },
            },
          });
  
          tour.addSteps([
            {
              title: '이미지 등록',
              attachTo: { element: '#gd_file_up', on: 'bottom-end' },
              text: `분석 할 이미지를 선택합니다.<br>확장자는 png, jpg 타입만 가능합니다.`,
              buttons: [
                {
                  action: tour.next,
                  text: '다음'
                },
              ],
            },
            {
              title: '이미지 붙여넣기',
              attachTo: { element: '#gd_copy_up', on: 'right' },
              text: `클립보드에 복사한 이미지를 붙여넣을 수 있습니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '다음',
                  action: tour.next,
                }
              ],
            },
            {
              title: '분석시작',
              attachTo: { element: '#gd_reading_btn', on: 'top-start' },
              text: `업로드 된 이미지를 분석합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '완료',
                  action() {
                    API_UPD_GUIDE.request(2);
                    return this.complete();
                  },
                }
              ],
            },
          ]);
          tour.start();
    };
}


export function startGuide3() {
    return () => {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
              modalOverlayOpeningRadius: 20,
              modalOverlayOpeningPadding: 20,
              cancelIcon: {
                enabled: true
              },
              when: {
                cancel: function() {
                    API_UPD_GUIDE.request(3)
                },
              },
            },
          });
  
          tour.addSteps([
            {
              title: '결과 이미지 확대',
              attachTo: { element: '#gd_img_view', on: 'right' },
              text: `이미지를 클릭해서 새창에서 확대한 화면으로 볼 수 있습니다.`,
              buttons: [
                {
                  action: tour.next,
                  text: '다음'
                },
              ],
            },
            {
              title: '결과 이미지 다운로드',
              attachTo: { element: '#gd_img_down', on: 'right' },
              text: `결과 이미지를 다운로드 합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '다음',
                  action: tour.next,
                }
              ],
            },
            {
              title: '이력 비교 및 그래프 조회',
              attachTo: { element: '#gd_hist_div', on: 'left' },
              text: `이전 결과 중 비교할 이력을 선택하거나 그래프를 조회합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '다음',
                  action: tour.next,
                }
              ],
            },
            {
              title: '결과 복사',
              attachTo: { element: '#gd_copy_btn', on: 'left' },
              text: `분석 결과를 클립보드에 복사합니다.`,
              buttons: [
                {
                  text: '이전',
                  action: tour.back,
                  classes: 'shepherd-button-secondary'
                },
                {
                  text: '완료',
                  action() {
                    API_UPD_GUIDE.request(3);
                    return this.complete();
                  },
                }
              ],
            },
          ]);
          tour.start();
    };
}