<template>
    <v-layout class="page" fill-height>
        <div class="box-container" >
            <xray-compo v-if="pineDx !== 'B'" :xrayType="XRAY_TYPE_SPINE" xrayNm="척추" :uploadComplete="uploadRefresh" 
            :removeFileToParent="removeFile" :xrayMap="xrayMap.spine" :showPictureDt="showPictureDt":pictureDtProps="pictureDt" @chgPictureDt="pictureDt = $event;" @updateTargetInfo="updateTargetInfo"/>
            <!-- @loaded="startGuideWhenLoaded"-->
            <xray-compo v-if="pineDx !== 'C'" :xrayType="XRAY_TYPE_HANDS" xrayNm="수부" :uploadComplete="uploadRefresh" 
            :removeFileToParent="removeFile" :xrayMap="xrayMap.hands" :showPictureDt="true" :pictureDtProps="pictureDt" @chgPictureDt="pictureDt = $event;" @updateTargetInfo="updateTargetInfo"/>
        </div>
    <Loading fullscreen="true" size="32" :text="messages" :isShow="loadingShow"/>
    <v-dialog v-model="popupVisible">
        <ConfirmPopup @goReading="goReading" @closePopup="closePopup" />
    </v-dialog>
    </v-layout>
</template>

<script>
import EventBus from '../../../plugins/EventBus'
import { API_COMMON_ERROR_MSG } from "../../../plugins/Const";
import { startGuide2 } from '../../../plugins/guide.js';

export default {
  middleware: "auth",
  components: {
    ConfirmPopup: () => import('@/popup/confirmPopup.vue')
  },
  inject: ["setRightSideHidden"],
  props: {},
  data() {
      return {
        xrayMap: {},
        messages: '',
        loadingShow: false,
        pictureDt: localStorage.getItem('pictureDt')?? this.getToDay(),
        uploadedXrayType: [],
        showPictureDt: false,
        pineDx: process.env.VUE_APP_PINE_DX,
        popupVisible: false,
        isLocalhost: false
      };
  },
  watch: {
    pictureDt(newValue){
      localStorage.setItem('pictureDt', newValue);
    }
  },
  created() {
    EventBus.on('reading', this.confirmPatientInfo);
    this.checkLocalhost();
  },
  mounted() {
    let storageMap = localStorage.getItem('xrayMap');
    let showPictureDt = localStorage.getItem("showPictureDt");
    if(storageMap){
      this.setRightSideHidden(true);
      storageMap = JSON.parse(storageMap);
      const keys = Object.keys(storageMap);
      if(this.targetInfo?.no!=storageMap[keys[0]]?.patientNo){
        localStorage.removeItem('xrayMap');
        this.pictureDt = this.getToDay();
      }else{
        this.xrayMap = storageMap;
        if (showPictureDt) {
          this.showPictureDt = JSON.parse(showPictureDt)
          this.uploadedXrayType = JSON.parse(localStorage.getItem("uploadedXrayType"))
        } else {
          if (storageMap?.spine) {
            if (!storageMap?.hands) {
              this.showPictureDt = true;
              this.uploadedXrayType = ['spine']
            } else {
              this.showPictureDt = false;
              this.uploadedXrayType = ['spine', 'hands']
            }
          } else {
            this.showPictureDt = false;
            this.uploadedXrayType = ['hands']
          }
        }
      }
    }else{
      this.setRightSideHidden(false);
    }
  },
  destroyed() {
      EventBus.off('reading', this.goReading);
      localStorage.removeItem("showPictureDt");
  },
  methods: {
      startGuideWhenLoaded(){
        if(this.userInfo.useGuide < 2){
          this.$nextTick(() => {
            const guideStarter = startGuide2();
            guideStarter(); 
          });
        }
      },
      uploadRefresh(xrayType,fileObj){
        this.$set(this.xrayMap, xrayType, fileObj);
        localStorage.setItem('xrayMap', JSON.stringify(this.xrayMap));
        if (!this.uploadedXrayType.includes(xrayType)) this.uploadedXrayType.push(xrayType);
        localStorage.setItem("uploadedXrayType", JSON.stringify(this.uploadedXrayType));
        this.setShowSpinePictureDt()
        this.setRightSideHidden(true);
      },
      removeFile(xrayType){
        this.$delete(this.xrayMap, xrayType);
        localStorage.setItem('xrayMap', JSON.stringify(this.xrayMap));
        if (xrayType == this.XRAY_TYPE_HANDS || xrayType == this.XRAY_TYPE_SPINE) this.pictureDt = this.getToDay();
        this.showToast(this.$t('page.analysis.msg.delete'));
        const indexToRemove = this.uploadedXrayType.indexOf(xrayType);
        if (indexToRemove !== -1) {
          this.uploadedXrayType.splice(indexToRemove, 1);
        }
        if(this.uploadedXrayType.length === 0) {
          this.updateTargetInfo(null)
          if(localStorage.getItem("pictureDt")) localStorage.removeItem("pictureDt")
          this.setRightSideHidden(false);
        }
        localStorage.setItem("uploadedXrayType", JSON.stringify(this.uploadedXrayType));
        this.setShowSpinePictureDt();
      },
      confirmPatientInfo(){
        if(!localStorage.getItem("targetInfo")) {
          this.showPopup(this.$t('page.analysis.msg.error.noInfo'))
          return;
        }
        if(!localStorage.getItem("xrayMap")) {
          this.showPopup(this.$t('page.analysis.msg.error.noXray'))
          return;
        }

        this.popupVisible = true

      },
      closePopup(){
        this.popupVisible = false
      },
      async goReading(){
        if(this.popupVisible) this.popupVisible = false
        const keys = Object.keys(this.xrayMap); // 객체의 키 배열을 가져옴
        let isBoth = null;
        if (keys.length === 0) {
          this.showPopup(this.$t('page.analysis.msg.error.noImg'))
          return;
        } else if(keys.length > 1) isBoth = true
        let isErrorOccurred = false;
        this.loadingShow = true;
        let xrayNo = 0;
        let indexProps = 0;
        for (const key of keys) {
          this.loadingShow = true;
          if(this.xrayMap[key]){
            this.targetInfo = JSON.parse(localStorage.getItem("targetInfo"))
            const eventSource = new EventSource('/api/reading/stream/'+xrayNo+'/'+key+'/'+this.targetInfo.no+'/'+this.xrayMap[key].no
                        + '/'+ (this.pictureDt == '' ? this.getToDay():this.pictureDt));
            await new Promise(resolve => {
              eventSource.onmessage = event => {
                if(event.data.startsWith('noAuth')){
                  this.loadingShow = false;
                  eventSource.close();
                  resolve(); 
                  isErrorOccurred = true; 
                  this.showPopup(event.data.replaceAll('noAuth:',''), 401);
                }else if (event.data.startsWith('[xrayNo]')) {
                  xrayNo = event.data.replaceAll('[xrayNo]','');
                }else if (event.data === 'end') {
                  this.loadingShow = false;
                  eventSource.close(); 
                  resolve(); 
                }else if (event.data.startsWith('error')) {
                  this.loadingShow = false;
                  eventSource.close(); 
                  resolve(); 
                  isErrorOccurred = true;
                  this.showPopup(event.data.replaceAll('error:',''));
                }else{
                  this.messages = event.data;
                }
              };
              eventSource.onerror = error => {
                console.log(error)
                this.loadingShow = false;
                this.showPopup(API_COMMON_ERROR_MSG);
                eventSource.close(); 
                resolve();
                isErrorOccurred = true; 
              };
            });
          }
          if (isErrorOccurred) {
              break;
          }
        }
        
        if (!isErrorOccurred) {
          if(this.xrayMap?.hands) {
            this.$set(this.xrayMap?.hands, 'pictureDt', this.pictureDt);
            localStorage.setItem('xrayMap', JSON.stringify(this.xrayMap));
          }
          if(keys[0] == this.XRAY_TYPE_HANDS) indexProps = 1;
          if(keys.length > 1) indexProps = 0;
          this.$router.push({name:'step2', query: { xrayNo: xrayNo, indexProps: indexProps}}); 
          localStorage.removeItem('pictureDt')
        }
    },
    setShowSpinePictureDt() {
      localStorage.removeItem("showPictureDt");
      if(this.uploadedXrayType.length === 2) {
        this.showPictureDt = false
      } else {
        if (this.uploadedXrayType.includes(this.XRAY_TYPE_SPINE)) {
          this.showPictureDt = true
        }
      }
      localStorage.setItem("showPictureDt", this.showPictureDt);
    },
    updateTargetInfo(newValue){
      this.targetInfo = newValue;
      if(!newValue) this.initLocalStorage()
      EventBus.emit('change_target');
    },
    checkLocalhost() {
      const hostname = window.location.hostname;
      this.isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1';
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";
.box-container {display: flex; gap:40px; width: 100%;  padding:40px;}

</style>